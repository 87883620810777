<template>
	<div class="rotated-div">
		<!--	人员考勤表-->
		<div style="">
			<vxe-table
				border
				height="200"
				:align="allAlign"
				:column-config="{isCurrent: true, isHover: true, resizable: true}"
				:row-config="{isCurrent: true, isHover: true}"
				:edit-config="{trigger: 'click', mode: 'cell'}"
				:data="kaoqin_list">
				<vxe-column field="realName" title="姓名" width="125" fixed="left"></vxe-column>
				<vxe-colgroup :title="month">
					<vxe-column v-for="(item, index) in month_days" :field="'kaoqin' + (index + 1)" :title="index + 1 + ''" width="38">
						<template #edit="{ row }">
<!--							<vxe-input v-model="row.['kaoqin' + (index + 1)]"></vxe-input>-->
						</template>
					</vxe-column>
				</vxe-colgroup>

				<vxe-column field="cq" title="出勤√" width="38"></vxe-column>
				<vxe-column field="cc" title="出差E" width="38"></vxe-column>
				<vxe-column field="xx" title="学习L" width="38"></vxe-column>
				<vxe-column field="nxj" title="年休假V" width="38"></vxe-column>
				<vxe-column field="hj" title="婚假W" width="38"></vxe-column>
				<vxe-column field="sj" title="丧假D" width="38"></vxe-column>
				<vxe-column field="tq" title="探亲F" width="38"></vxe-column>
				<vxe-column field="cj" title="产假M" width="38"></vxe-column>
				<vxe-column field="bj" title="病假S" width="38"></vxe-column>
				<vxe-column field="dg" title="待岗X" width="38"></vxe-column>
				<vxe-column field="shij" title="事假P" width="38"></vxe-column>
				<vxe-column field="kg" title="旷工A" width="38"></vxe-column>
			</vxe-table>
		</div>
		<div style="margin-top: 8%;margin-right: -85%" v-if="kq_button">
			<el-button type="primary" @click="userConfirmKaoqin">考勤确认</el-button>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import {setNowMonth2} from "@/utils/time";

export default {
	name: "Phone_kaoqin",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			kaoqin_list: [],
			month_days: '',
			allAlign: 'center',
			month: "",
			kaoqin: '',
			kaoqins: [
				{
					value: '√',
					label: '√',
				},
				{
					value: 'E',
					label: 'E',
				},
				{
					value: 'L',
					label: 'L',
				},
				{
					value: 'V',
					label: 'V',
				},
				{
					value: 'W',
					label: 'W',
				},
				{
					value: 'D',
					label: 'D',
				},
				{
					value: 'F',
					label: 'F',
				},
				{
					value: 'M',
					label: 'M',
				},
				{
					value: 'S',
					label: 'S',
				},
				{
					value: 'X',
					label: 'X',
				},
				{
					value: 'P',
					label: 'P',
				},
				{
					value: 'A',
					label: 'A',
				}
			],
			kaoqin_show: [],
			screenWidth: '',
			screeHeight: '',
			kq_id: '',
			kq_button: true,
			user_id: ''
		}
	},
	created() {
		if (!this.$route.params.key){
			this.$router.push("/error")
		}else {
			this.user_id = this.$route.params.user_id
			this.confirmKaoqin()
		}
	},
	methods: {
		confirmKaoqin(){
			this.screenWidth  = document.documentElement.clientWidth
			this.screeHeight  = document.documentElement.clientHeight
			this.axios.get('/wageKaoqin/confirmKaoqin', (response) => {
				this.kaoqin_list = response.obj
				this.month_days = response.obj2
				this.month = response.obj3
				this.kq_id = this.kaoqin_list[0].id
			},{
				user_id: this.user_id
			})
		},
		userConfirmKaoqin(){
			this.axios.post('/wageKaoqin/updataKaoqin', (response) => {
				if (response.obj){
					this.kq_button = false
					ElMessage.success('确认成功!')
				}else {
					ElMessage.error('确认失败，请联系科信部!')
				}
			},{
				id: this.kq_id
			})
		}
	}
}
</script>

<style>
.rotated-div {
	overflow: hidden;
	text-align: center;
	/* 设置div的宽度和高度 */
	width:189vh;
	/* 背景颜色，仅为了更明显地展示div */
	//background-color: #f00;
	/* 设置旋转中心点为div的中心 */
	transform-origin: center top;
	/* 旋转90度 */
	transform: rotate(90deg);
	/* 将旋转后的div定位回到原来的位置 */
	position: absolute;
	top: 50%;
	left: 5%;
	/* 使用负外边距将div左移50%其宽度，上移50%其高度，从而居中 */
	margin-left:-90%;
	margin-top: 150%;
}
</style>